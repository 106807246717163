// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { autoServiceApiV2 } from '../../../services/autoServiceApiV2';
import Card from '../../Cards/UsedVehicles/PortalUsedVehiclesCardV2';
import PlaceholderCard from '../../Cards/Placeholder';
import currentStockUnitStore from '../../../stores/CurrentStockUnit';

export default class PortalUsedVehiclesCarouselV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedVehiclesList: [],
      currentUnitState: null,
      isFetching: true,
    };

    this.carousel = null;

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchUsedVehicles = this.fetchUsedVehicles.bind(this);
    this.watchStockUnitState();
  }

  componentDidMount() {
    this.initCarousel();
    this.fetchUsedVehicles();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  watchStockUnitState() {
    currentStockUnitStore().watch(state => {
      this.setState(
        {
          currentUnitState: state.currentUnit,
          isFetching: true,
        },
        () => {
          this.fetchUsedVehicles();
        },
      );
    });
  }

  async fetchUsedVehicles() {
    let usedVehiclesListResult = [];

    try {
      const { customParams, sortParams } = this.props;
      const { currentUnitState } = this.state;

      const requestParams = [...customParams];

      if (sortParams) {
        requestParams.push({ sort: sortParams });
      }

      if (currentUnitState) {
        requestParams.push({ 'unit_id[]': currentUnitState.id });
      }

      const result = await autoServiceApiV2.getUsedModels(requestParams);
      const usedVehiclesFeaturedList = result
        ? result.entries
        : this.state.usedVehiclesList;

      usedVehiclesListResult = usedVehiclesFeaturedList;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(oldState => ({
        ...oldState,
        usedVehiclesList: usedVehiclesListResult,
        isFetching: false,
      }));
    }
  }

  initCarousel() {
    if (!this.elCarousel) return;

    const { isFetching, usedVehiclesList } = this.state;
    if (!isFetching && usedVehiclesList.length <= 0) return;

    if (this.carousel) this.carousel.destroy();

    const carouselConfig = {
      gap: 30,
      perPage: 4,
      perMove: 4,
      pagination: false,
      lazyLoad: 'nearby',
      preloadPages: 0,
      rewind: false,
      bound: true,
      focus: 'center',
      trimSpace: true,
      breakpoints: {
        560: {
          perPage: 1.3,
          perMove: 1,
        },
        992: {
          perPage: 2,
        },
        1270: {
          perPage: 3,
        },
        1400: {
          perPage: 4,
        },
      },
    };

    if (usedVehiclesList.length <= carouselConfig.perPage) {
      carouselConfig.arrows = false;
      carouselConfig.focus = 'number';
    }

    const carousel = window.makeCarousel(this.elCarousel, carouselConfig);

    this.carousel = carousel;
    window.matchHeight.match('.used-vehicles-carousel-component');
  }

  render() {
    const { usedVehiclesList, isFetching } = this.state;
    const { moduleLink, carouselTitle, buttonDesktopLabel, buttonMobileLabel } =
      this.props;

    return (
      <div className="used-vehicles-carousel-component container">
        {usedVehiclesList.length > 0 && (
          <div className="used-vehicles-carousel__content">
            <div className="used-vehicles-carousel__top">
              <h2 className="used-vehicles-carousel__title">{carouselTitle}</h2>
              <a href={moduleLink} className="btn button button--hollow">
                <span className="only-desktop"> {buttonDesktopLabel} </span>
                <span className="only-mobile"> {buttonMobileLabel} </span>
              </a>
            </div>
          </div>
        )}

        <div
          ref={node => {
            this.elCarousel = node;
          }}
          className="carousel used-vehicles-carousel splide"
        >
          <div className="splide__track">
            {isFetching && (
              <ul className="used-vehicles__carousel-items-wrapper splide__list">
                {Array.from({ length: 5 }).map(item => (
                  <li
                    className="used-vehicles__carousel-placeholder-item carousel__item splide__slide"
                    key={item}
                  >
                    <PlaceholderCard long />
                  </li>
                ))}
              </ul>
            )}
            {!isFetching && usedVehiclesList.length > 0 && (
              <ul className="used-vehicles__carousel-items-wrapper splide__list">
                {usedVehiclesList.map((usedModel, index) => (
                  <li
                    className="used-vehicles__carousel-item carousel__item splide__slide"
                    key={usedModel.slug}
                  >
                    <Card
                      moduleLink={moduleLink}
                      slug={usedModel.slug}
                      itemImage={usedModel.item_image.middle_image}
                      model={usedModel.model}
                      version={usedModel.subtitle}
                      name={usedModel.name}
                      itemUnit={usedModel.item_unit}
                      oldPrice={usedModel.old_price}
                      price={usedModel.price_value || usedModel.price}
                      exchange={usedModel.exchange}
                      kind={usedModel.kind}
                      modelYear={usedModel.model_year}
                      fabricationYear={usedModel.fabrication_year}
                      km={usedModel.km}
                      isOnFirstPage={index < 4}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PortalUsedVehiclesCarouselV2.defaultProps = {
  customParams: [],
  sortParams: '-price,-ordination',
  moduleLink: '/seminovos',
  maxItems: 16,
};

PortalUsedVehiclesCarouselV2.propTypes = {
  customParams: PropTypes.arrayOf(PropTypes.object),
  sortParams: PropTypes.string,
  moduleLink: PropTypes.string,
  maxItems: PropTypes.number,
};
