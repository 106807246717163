/* eslint-disable no-return-assign */
import CurrentStockUnit from '../../auto/stores/CurrentStockUnit';

(() => {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  const store = {
    CurrentStockUnit,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));
})();
