import { getCookie } from '../utils/cookies';
import { createStore, createEvent } from 'effector';

export const setActiveUnit = createEvent('set active unit');
export const setModalExhibition = createEvent('set modal exhibition')

const CurrentStockUnit = (() => {
  let instance;

  const modalAlreadyClosed = getCookie('current_stock_unit_closed')
  
  const defaultState = {
    currentUnitState: null,
    modalIsOpen: modalAlreadyClosed !== 'true'
  };

  const activeUnitCookie = getCookie('current_stock_unit');
  const storedActiveUnitData = window.sessionStorage.getItem('defaultActiveUnit');

  if (storedActiveUnitData && activeUnitCookie) {
    const storedActiveUnit = JSON.parse(storedActiveUnitData);

    const hasValidStoredUnit = storedActiveUnit && storedActiveUnit.id === parseInt(activeUnitCookie)

    if (hasValidStoredUnit) {
      defaultState.currentUnitState = storedActiveUnit;
      defaultState.modalIsOpen = false
    }
  }

  return (
    defaultValue = {
      currentUnit: defaultState.currentUnitState,
      previousUnit: null,
      modalIsOpen: defaultState.modalIsOpen,
      events: {
        setActiveUnit,
        setModalExhibition
      },
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setActiveUnit, (state, currentUnit) => {
        return {
          ...state,
          previousUnit: state.currentUnit,
          currentUnit
        }
      });

      instance.on(setModalExhibition, (state, modalExhibition = false) => ({
        ...state,
        modalIsOpen: modalExhibition
      }))
    }

    return instance;
  };
})();

export default CurrentStockUnit;
