// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import autoServiceGroupApi from '../../utils/autoServiceGroupApi';
import PlaceholderCard from '../Cards/Placeholder';

export default class PortalUnitsCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unitsList: [],
      isFetching: true,
    };

    this.carousel = null;

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchUnits = this.fetchUnits.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
    this.fetchUnits();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  async fetchUnits() {
    let unitsListResult = [];

    try {
      const result = await autoServiceGroupApi.getUnits();
      unitsListResult = result ? result.entries : this.state.unitsList;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(oldState => ({
        ...oldState,
        unitsList: unitsListResult,
        isFetching: false,
      }));
    }
  }

  initCarousel() {
    if (!this.elCarousel) return;

    const { isFetching, unitsList } = this.state;
    if (!isFetching && unitsList.length <= 0) return;

    if (this.carousel) this.carousel.destroy();

    const carouselConfig = {
      gap: 30,
      perPage: 3,
      perMove: 3,
      pagination: false,
      lazyLoad: 'nearby',
      preloadPages: 0,
      rewind: false,
      bound: true,
      focus: 'center',
      trimSpace: true,
      breakpoints: {
        560: {
          perPage: 1.125,
          perMove: 1,
        },
        992: {
          perPage: 2,
        },
        1400: {
          perPage: 3,
        },
      },
    };

    if (unitsList.length <= carouselConfig.perPage) {
      carouselConfig.arrows = false;
      carouselConfig.focus = 'number';
    }

    const carousel = window.makeCarousel(this.elCarousel, carouselConfig);

    this.carousel = carousel;
    window.matchHeight.match('.units-carousel-component');
  }

  render() {
    const { unitsList, isFetching } = this.state;
    const {
      moduleLink,
      carouselTitle,
      buttonDesktopLabel,
      buttonMobileLabel,
      maxItems,
    } = this.props;

    const displayedUnitsList = unitsList.slice(0, maxItems);

    return (
      <div className="units-carousel-component container">
        {displayedUnitsList.length > 0 && (
          <div className="units-carousel__content">
            <div className="units-carousel__top">
              <h2 className="units-carousel__title">{carouselTitle}</h2>
              <a href={moduleLink} className="btn button button--hollow">
                <span className="only-desktop"> {buttonDesktopLabel} </span>
                <span className="only-mobile"> {buttonMobileLabel} </span>
              </a>
            </div>
          </div>
        )}

        <div
          ref={node => {
            this.elCarousel = node;
          }}
          className="carousel units-carousel splide"
        >
          <div className="splide__track">
            {isFetching && (
              <ul className="units__carousel-items-wrapper splide__list">
                {Array.from({ length: 5 }).map(item => (
                  <li
                    className="units__carousel-placeholder-item carousel__item splide__slide"
                    key={item}
                  >
                    <PlaceholderCard long />
                  </li>
                ))}
              </ul>
            )}
            {!isFetching && unitsList.length > 0 && (
              <ul className="units__carousel-items-wrapper splide__list">
                {displayedUnitsList.map(item => (
                  <li
                    className="units__carousel-item carousel__item splide__slide"
                    key={item.slug}
                  >
                    <Item
                      imageSrc={item.item_image.thumb_image}
                      title={item.name}
                      fullAddress={item.complete}
                      phones={item.item_phones}
                      itemHours={item.item_hours}
                      mapLink={item.map_url}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PortalUnitsCarousel.defaultProps = {
  moduleLink: '/nossas-lojas',
  carouselTitle: 'Nossas Unidades',
  buttonDesktopLabel: 'Ver todas as unidades',
  buttonMobileLabel: 'Ver unidades',
  maxItems: 8,
};

PortalUnitsCarousel.propTypes = {
  moduleLink: PropTypes.string,
  carouselTitle: PropTypes.string,
  buttonDesktopLabel: PropTypes.string,
  buttonMobileLabel: PropTypes.string,
  maxItems: PropTypes.number,
};
