import './stores';
import './components';
import helpers from '../../auto/helpers';
import { handlePopupClose } from '../../auto/utils/components/pop-up';
import serviceConversionFactory from '../../auto/autoBoxOfficeServiceFactory';
import hookConversion from '../../auto/utils/hookConversion';
import equalHeights from '../../auto/utils/equalHeights';
import { initSectionWhenItIsVisible } from '../../auto/utils/initSectionWhenItIsVisible';
import { autoServiceApiV2 } from '../../auto/services/autoServiceApiV2';
import autoServiceGroupApi from '../../auto/utils/autoServiceGroupApi';
import { setDropdownInteration } from '../../auto/utils/components/dropdown'

window.helpers = {
  ...helpers,
  initSectionWhenItIsVisible,
};

window.components = {
  setDropdownInteration,
};

window.serviceConversion = serviceConversionFactory();
window.hookConversion = hookConversion;
window.autoServiceGroupApi = autoServiceGroupApi;

window.matchHeight = {
  match: equalHeights,
};

window.services = {
  autoServiceApiV2,
};

handlePopupClose();
