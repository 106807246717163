/* eslint-disable react/self-closing-comp */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

export default class SearchFilter extends Component {
  constructor(props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  handleSearchChange(event) {
    this.props.onSearchChange(event.target.value);
  }

  handleStateChange(event) {
    this.props.onStateChange(event.target.value);
  }

  render() {
    const validStates = this.props.states.filter(state => state.trim() !== '');

    return (
      <div className="filters">
        <div className="search-term__wrapper">
          <i className="icon icon-search"></i>
          <input
            type="text"
            placeholder="Buscar"
            value={this.props.searchQuery}
            onInput={this.handleSearchChange}
          />
        </div>

        {validStates.length > 0 && (
          <div className="custom-select-wrapper">
            <select
              value={this.props.selectedState}
              onChange={this.handleStateChange}
            >
              <option value="">Filtrar estado</option>
              {validStates.map(state => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <i className="icon icon-arrow-d"></i>
          </div>
        )}
      </div>
    );
  }
}

SearchFilter.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  selectedState: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired, // Adiciona as UFs como PropTypes
  onSearchChange: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
};
