/* eslint-disable react/self-closing-comp */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class Item extends Component {
  render() {
    const { imageSrc, title, fullAddress, phones, itemHours, mapLink } =
      this.props;
    const defaultImg =
      'https://static.autoforce.com/plugins/images/global/icones/unit_default.png';

    return (
      <div className="card">
        {this.props.showItemImage && (
          <img
            src={imageSrc || defaultImg}
            alt={`Imagem da unidade ${title}`}
            width="384"
            height="200"
          />
        )}

        <div className="store-details-card">
          <h2 className="store-title">{title}</h2>
          <h3 className="text-light-store">{fullAddress}</h3>

          <hr />

          {phones.length > 0 && (
            <div className="store-card-contact-info">
              <strong className="d-block">Telefone e Whatsapp</strong>
              <div className="phones-list">
                <div className="phone-item">
                  <span className="d-block text-light-store">
                    {phones[0].name}
                  </span>
                  <span className="phone-number">{phones[0].number}</span>
                </div>

                {phones.length > 1 && <hr className="vertical-hr" />}

                {phones.length > 1 && (
                  <div className="phone-item">
                    <span className="d-block text-light-store">
                      {phones[1].name}
                    </span>
                    <span className="phone-number">{phones[1].number}</span>
                  </div>
                )}
              </div>
            </div>
          )}

          {itemHours.length > 0 && (
            <div className="item-hours">
              {itemHours.map(item => (
                <span className="d-block text-light-store">
                  {item.title}: {item.description}
                </span>
              ))}
            </div>
          )}

          {mapLink && (
            <a
              className="link-map-store"
              href={mapLink}
              target="_blank"
              rel="noreferrer"
            >
              Ver no mapa
              <i className="icon icon-map-pin"></i>
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default Item;

Item.defaultProps = {
  imageSrc:
    'https://static.autoforce.com/plugins/images/global/icones/unit_default.png',
  title: '',
  fullAddress: '',
  phones: [],
  itemHours: [],
  mapLink: '',
  showItemImage: true,
};

Item.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  fullAddress: PropTypes.string,
  phones: PropTypes.arrayOf,
  itemHours: PropTypes.arrayOf,
  mapLink: PropTypes.string,
  showItemImage: PropTypes.bool,
};
