// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import autoServiceGroupApi from '../../utils/autoServiceGroupApi';
import SearchFilter from './SearchFilter';

export default class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      units: [],
      filteredUnits: [],
      displayedUnits: [],
      states: [],
      itemsPerPage: 9,
      currentPage: 1,
      searchQuery: '',
      selectedState: '',
    };

    this.setUnitsData();

    this.getUnitsData = this.getUnitsData.bind(this);
    this.setUnitsData = this.setUnitsData.bind(this);
    this.setUnitsState = this.setUnitsState.bind(this);
    this.filterUnits = this.filterUnits.bind(this);
    this.loadMoreUnits = this.loadMoreUnits.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  handleSearchChange(searchQuery) {
    this.setState({ searchQuery, currentPage: 1 }, this.filterUnits);
  }

  handleStateChange(selectedState) {
    this.setState({ selectedState, currentPage: 1 }, this.filterUnits);
  }

  getUnitsData() {
    return autoServiceGroupApi.getGroupUnits();
  }

  setUnitsData() {
    this.getUnitsData().then(data => this.setUnitsState(data));
  }

  setUnitsState(data) {
    const units = data.units;
    const states = [...new Set(units.map(unit => unit.state))];

    this.setState(
      {
        units,
        filteredUnits: units,
        states,
      },
      this.updateDisplayedUnits,
    );
  }

  filterUnits() {
    const { units, searchQuery, selectedState } = this.state;

    const filteredUnits = units.filter(unit => {
      const matchesSearchQuery = unit.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesState = selectedState
        ? unit.state.toLowerCase() === selectedState.toLowerCase()
        : true;

      return matchesSearchQuery && matchesState;
    });

    this.setState({ filteredUnits }, this.updateDisplayedUnits);
  }

  updateDisplayedUnits() {
    const { filteredUnits, itemsPerPage, currentPage } = this.state;
    const startIndex = 0;
    const endIndex = itemsPerPage * currentPage;

    this.setState({
      displayedUnits: filteredUnits.slice(startIndex, endIndex),
    });
  }

  loadMoreUnits() {
    this.setState(
      prevState => ({
        currentPage: prevState.currentPage + 1,
      }),
      this.updateDisplayedUnits,
    );
  }

  render() {
    const {
      displayedUnits,
      filteredUnits,
      itemsPerPage,
      currentPage,
      searchQuery,
      selectedState,
      states,
    } = this.state;

    const hasMoreUnits = filteredUnits.length > itemsPerPage * currentPage;
    const itemsToDisplay = this.props.showHeader
      ? displayedUnits
      : displayedUnits.slice(0, 3);

    return (
      <div className="our-stores-listing">
        {this.props.showHeader && (
          <header className="our-stores-listing__header container">
            <h1 className="our-stores__title">Nossas lojas</h1>

            <SearchFilter
              searchQuery={searchQuery}
              selectedState={selectedState}
              states={states}
              onSearchChange={this.handleSearchChange}
              onStateChange={this.handleStateChange}
            />
          </header>
        )}

        <div className="our-stores-listing__list container">
          {itemsToDisplay.map(item => (
            <Item
              imageSrc={item.item_image.thumb_image}
              title={item.name}
              fullAddress={item.complete}
              phones={item.item_phones}
              itemHours={item.item_hours}
              mapLink={item.map_url}
              showItemImage={this.props.showItemImage}
            />
          ))}
        </div>

        {this.props.showHeader && hasMoreUnits && (
          <button
            onClick={this.loadMoreUnits}
            className="load-more-button"
            type="button"
          >
            Ver mais
          </button>
        )}
      </div>
    );
  }
}

List.defaultProps = {
  showHeader: true,
  showItemImage: true,
};

List.propTypes = {
  showHeader: PropTypes.bool,
  showItemImage: PropTypes.bool,
};
